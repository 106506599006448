import React, { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosArrowBack } from 'react-icons/io'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface Props {
  pageContext: {
    photo: string
    code: string
    ingredients: {
      code: string
      amount: number
      measure?: string
    }[]
  }
}

const Cocktail: React.FC<Props> = props => {
  const {} = props

  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: props.pageContext.ingredients.reduce((acc, ingredient) => {
      acc[ingredient.code] = ingredient.amount
      return acc
    }, {} as { [key: string]: string | number }),
  })

  const onSubmit = () => {
    reset()
  }

  const getIngredientAmount = (code: string) => {
    const result = props.pageContext.ingredients.find(
      ingredient => ingredient.code === code
    )

    if (result && result.amount) {
      return result.amount
    } else {
      return 1
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentIngredientCode = e.currentTarget.name
    const newAmount = +e.currentTarget.value
    const originalAmount = getIngredientAmount(currentIngredientCode)
    const coeficient = newAmount / originalAmount

    props.pageContext.ingredients.forEach(ingredient => {
      if (ingredient.code !== currentIngredientCode) {
        setValue(ingredient.code, Math.round(ingredient.amount * coeficient))
      }
    })
  }

  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center px-4 w-full m-auto pb-20 md:w-1/2">
      <Helmet title={t(`cocktails.${props.pageContext.code}`)} defer={false} />

      <div className="w-full py-4 flex justify-between">
        <div className="flex items-center">
          <Link className="flex items-center" to="/">
            <IoIosArrowBack size={24} />
            {t('titles.cocktails')}
          </Link>
        </div>
        <div className="text-2xl">
          {t(`cocktails.${props.pageContext.code}`)}
        </div>
      </div>
      <div className="rounded-2xl overflow-hidden h-full w-full">
        <img
          className="w-full object-fill object-center"
          src={require(`../${props.pageContext.photo}`)}
        />
      </div>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="mt-2 w-full"
      >
        {props.pageContext.ingredients.map(ingredient => (
          <div className="mt-4 relative truncate" key={ingredient.code}>
            <div className="text-3xl absolute py-3 ml-2 text-gray-400 block truncate">
              {t(`ingredients.${ingredient.code}`)}
              <span className="text-sm">
                ({t(`measurements.${ingredient.measure || 'ml'}`)})
              </span>
            </div>

            <input
              onChange={handleChange}
              ref={register}
              name={ingredient.code}
              onClick={e => e.currentTarget.select()}
              className="text-3xl border-solid border w-full py-3 text-right pr-4"
              type="text"
              pattern="\d*"
            />
          </div>
        ))}

        <button className="mt-4 text-gray-600 border-dashed border-b">
          {t('reset')}
        </button>
      </form>
    </div>
  )
}

export default Cocktail
